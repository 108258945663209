@import "../../node_modules/react-quill/dist/quill.snow.css";

.ql-toolbar {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-color: #e0e0e0 !important;
	font-family: "DM Sans" !important;
}

.ql-container {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-color: #e0e0e0 !important;
	height: 200px;
	font-family: "DM Sans";
	font-size: 14px;
	color: #1e0e62;
}
