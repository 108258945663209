.react-tel-input {
	border-radius: 8px;
	height: 40px;
	&::before {
		content: "";
		background-color: #cbd5e0;
		width: 1px;
		height: 24px;
		position: absolute;
		z-index: 10;
		left: 42px;
		top: 8px;
	}
}

.react-tel-input .form-control {
	height: 40px !important;
	border-radius: 8px !important;
	border-color: #cbd5e0;
	font-size: 14px !important;
	line-height: 22px !important;
	color: #1e0e62 !important;
	font-family: "DM Sans", sans-serif !important;
	width: inherit !important;
}

.phone_input_error {
	box-shadow: 0 0 0 1px #e53e3e !important;
	border-color: #e53e3e !important;
}

.phone_input_container {
	font-family: inherit;
}

.phone_input_button {
	border-radius: 8px 0 0 8px !important;
	border-color: #cbd5e0;
	border-right: none !important;
	background-color: transparent !important;
}

.phone_input_self_input::placeholder {
	color: #a9b0b6;
	font-size: 14px !important;
}

.phone_input_dropdown {
	font-family: "DM Sans", sans-serif;
	color: #1e0e62;
	z-index: 15 !important;
}

.react-tel-input .selected-flag:hover {
	background-color: transparent !important;
}
