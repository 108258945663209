.input-price {
	width: 100%;
	outline: 2px solid transparent;
	outline-offset: 2px;
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 14px;
	-webkit-padding-start: 12px;
	padding-inline-start: 12px;
	-webkit-padding-end: 12px;
	padding-inline-end: 12px;
	height: 40px;
	border-radius: 8px;
	border: 1px solid #e0e0e0;
	background: inherit;
	color: #1e0e62;
	line-height: 22px;
}

.input-price-error {
	box-shadow: 0 0 0 1px #e53e3e;
	border-color: #e53e3e;
}

.input-price:focus {
	box-shadow: 0 0 0 1px #8c4bc9;
	border-color: #8c4bc9;
}
